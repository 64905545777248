import APIService from '@/services/api-service'
const primary = '/job'
const secondary = 'summary'

class SummaryService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getJobDetail (id: any) {
    return this.connector.get(`${primary}/${secondary}/${id}/detail`)
  }

  public getJobInventories (id: any) {
    return this.connector.get(`${primary}/${secondary}/${id}/inventories`)
  }

  public getBargeDetails (id: any) {
    return this.connector.get(`${primary}/${secondary}/${id}/barge-details`)
  }

  public getDispatchDetails (id: any) {
    return this.connector.get(`${primary}/${secondary}/${id}/dispatch-details`)
  }
}

export default new SummaryService()
