
import {
  Component,
  Vue
} from 'vue-property-decorator'
import DetailsWidget from '../../../widget/Details.vue'
import {
  JobModule
} from '../../../store/JobModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  NotifyModule
} from '@/store/modules/NotifyModule'
import lazyLoad from '@/components/LazyLoad'
  @Component({
    name: 'JobOperation',
    components: {
      DetailsWidget
    }
  })
export default class JobOperation extends Vue {
    protected showComponent = false

    get job () {
      return JobModule.job
    }

    get route () {
      return JobModule.route
    }

    get permissions () {
      return AuthModule.permissions
    }

    get bodyComponent () {
      switch (JobModule.route) {
        case 'Setting':
          return () => lazyLoad(import('../Body/Settings/index.vue'))

        case 'Documentation':
          return () => lazyLoad(import('../Body/Letter/index.vue'))

        case 'Collection':
          return () => lazyLoad(import('../Body/Collection/Layout.vue'))

        case 'Dispatch':
          return () => lazyLoad(import('../Body/Dispatch/index.vue'))

        case 'Complete':
          return () => lazyLoad(import('../Body/Complete/index.vue'))
      }
    }

    public show () {
      JobModule.setOperationsRoute('Setting')
      this.showComponent = true;
      (this.$refs.DetailsWidget as any).show(JobModule.job)
    }

    public hide () {
      JobModule.destroy()
      this.showComponent = false;
      (this.$refs.DetailsWidget as any).reset()
    }

    public routeToJobSteps (routeName: string) {
      if (this.job.commenced_date === null) {
        NotifyModule.set('Please commence the job to continue..')
        return false
      }
      JobModule.setOperationsRoute(routeName)
    }
}

