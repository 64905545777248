
import {
  Component,
  Vue
} from 'vue-property-decorator'
import SummaryService from '../services/summary-service'
import Status from '@/components/Status/index.vue'
  @Component({
    name: 'DetailsWidget',
    components: {
      Status
    }
  })
export default class DetailsWidget extends Vue {
    protected job: any = {}

    get length () {
      return Object.keys(this.job).length
    }

    public show (job: any) {
      this.populateJobDetail(job)
    }

    public reset () {
      this.job = {}
    }

    protected async populateJobDetail (job: any) {
      const response = await SummaryService.getJobDetail(job.id)
      this.job = response.data
    }
}

